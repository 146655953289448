import { useTranslation } from "react-i18next";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import ExplicitIcon from '@mui/icons-material/Explicit';
import CategoryIcon from '@mui/icons-material/Category';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import AttachFileIcon from '@mui/icons-material/AttachFile';

export function useMenuItems() {
  const { t } = useTranslation();

  
const dashboard = {
    id: 'dashboard',
    title: t('Dashboard'),
    type: 'group',
    children: [
      {
        id: 'default',
        title: t('Dashboard'),
        type: 'item',
        url: '/',
        icon: DashboardIcon,
        breadcrumbs: false
      },
      {
        id: 'files',
        title: t('Files'),
        type: 'item',
        url: '/files',
        icon: AttachFileIcon,
        breadcrumbs: false
      },
      {
        id: 'newCategory',
        title: t('Category'),
        type: 'item',
        url: '/category',
        icon: CategoryIcon,
        target: false
      },
    ]
  };

 

  const pages = {
    id: 'pages',
    title: t('Pages'),
    caption: t('Pages Caption'),
    type: 'group',
    children: [
      {
        id: 'staticpages',
        title: t('Pages'),
        type: 'collapse',
        icon: ArticleIcon,
        children: [
          {
            id: 'newstaticpages',
            title: t('New'),
            type: 'item',
            url: '/content',
            target: true
          },
          {
            id: 'allstaticpages',
            title: t('All Pages'),
            type: 'item',
            url: '/allpages',
            target: true
          }
        ]
      }
    ]
  };

const products = {
  id: 'product',
  title: t('Products'),
  caption: t('Product') +" "+t('Operation'),
  type: 'group',
  children: [
    {
      id: 'product',
      title: t('Products'),
      type: 'collapse',
      icon: RestaurantIcon,
      children: [
        {
          id: 'newProduct',
          title: t('New Product'),
          type: 'item',
          url: '/product',
          target: false
        },
        {
          id: 'listProduct',
          title: t('Products'),
          type: 'item',
          url: '/products',
          target: false
        }
      ]
    }
  ]

}
const menuItems = {
  items: [
    dashboard,
    pages,
  ]
}
  return menuItems;
}
