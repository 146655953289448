const config = {
    // baseURL: 'http://127.0.0.1:5000/',
    baseURL:'https://cumhuriyetapi.ikon-x.com.tr/',
    appName:'IKONX CMS',
    version:'1.1.0',
    versionNum:11.0,
    logo:"/logo_default.png",
    createAccountLink:true
  };
  
  export default config;
  