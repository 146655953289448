import { $createLinkNode } from "@lexical/link"
import { $createListItemNode, $createListNode } from "@lexical/list"
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text"
import { $createParagraphNode, $createTextNode, $getRoot , EditorState } from "lexical"
import React , { useState, useRef, useEffect } from 'react';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import { TableContext } from "./plugins/TablePlugin";
import { SharedAutocompleteContext } from "./context/SharedAutocompleteContext";
import Editor from "./Editor";
import logo from "./images/logo.svg";
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import Settings from "./Settings";
import DocsPlugin from "./plugins/DocsPlugin";
import PasteLogPlugin from "./plugins/PasteLogPlugin";
import TestRecorderPlugin from "./plugins/TestRecorderPlugin";
import TypingPerfPlugin from "./plugins/TypingPerfPlugin";
import { useSettings } from "./context/SettingsContext";
import { isDevPlayground } from "./appSettings"

console.warn(
    "If you are profiling the playground app, please ensure you turn off the debug view. You can disable it by pressing on the settings control in the bottom-left of your screen and toggling the debug view setting."
)


function Playground( {content ,onContentChange}) {
    const {
        settings: { isCollab, emptyEditor, measureTypingPerf }
    } = useSettings();

    const editorRef = useRef(null);
    const [currentEditorState, setCurrentEditorState] = useState(null);

    const initialConfig = {
        editorState: emptyEditor,
        namespace: "Playground",
        nodes: [...PlaygroundNodes],
        onError: error => {
            throw error;
        },
        theme: PlaygroundEditorTheme,
        editable:true
    };

    const handleButtonClick = () => {

        if (editorRef.current) {
            const editorContent = editorRef.current.read();
            setCurrentEditorState(editorContent);
            console.log(editorContent);
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            const editorState = editorRef.current.state;
            setCurrentEditorState(editorState);
            onChange(editorState)
        }
    }, [editorRef.current]);

    useEffect(() => {
        if (editorRef.current) {
            // content prop ile başlangıç içeriğini ayarla
            const editorState = EditorState.createFromJSON(content);
            editorRef.current.update(editorState);
        }
    }, [content]); // content değiştiğinde bu useEffect çalışır

    const onChange = (editorState) => {
        editorState.read(() => {
            // İçeriği üst bileşene bildir
            const value = JSON.stringify(editorState); // veya editorState.toJSON()
            console.log(value);
            // onContentChange fonksiyonun varlığını ve fonksiyon tipinde olduğunu kontrol et
            if (typeof onContentChange === 'function') {
                onContentChange(value);
            } else {
                console.error('onContentChange is not a function');
            }
        });
    };

    return (
        <>
        <LexicalComposer initialConfig={initialConfig}>
            <SharedHistoryContext>
                <TableContext>
                    <SharedAutocompleteContext>
                        <div className="editor-shell">
                        <Editor ref={editorRef} />
                        </div>
                  
                        {isDevPlayground ? <PasteLogPlugin /> : null}
                        {isDevPlayground ? <TestRecorderPlugin /> : null}
                        {measureTypingPerf ? <TypingPerfPlugin /> : null}
                    </SharedAutocompleteContext>
                </TableContext>
            </SharedHistoryContext>
                <OnChangePlugin
                    onChange={editorState => {
                          const value = JSON.stringify(editorState); // or JSON.stringify(editorState.toJSON())
                        onContentChange(value)
                    }}
                />
        </LexicalComposer>
        
          

        </>
    );
}

export default Playground;
