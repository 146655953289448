import instance from './axiosConfig';

export const postSystemVariable = async (data) => {
  try {
    const response = await instance.post('/systemVariable', data);
    return response.data;
  } catch (error) {
    console.error('Error posting system variable:', error);
    throw error;
  }
};

export const getSystemVariable = async (key) => {
  try {
    const response = await instance.get(`/api/system/${key}`);
    return response.data;
  } catch (error) {
    console.error('Error getting system variable:', error);
    throw error;
  }
};

export const getStorageVariables = async () => {
  try {
    const response = await instance.get('/api/system/storage');
    return response.data;
  } catch (error) {
    console.error('Error getting storage variables:', error);
    throw error;
  }
};

export const uploadFiles = async (files, onUploadProgress) => {
  try {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });

    const response = await instance.post('/api/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const getFiles = async (searchTerm = '', page = 1, pageSize = 20, excludeIds = [], fileType = '') => {
  try {
    // Sorgu parametrelerini oluştur
    let query = `?page=${page}&pageSize=${pageSize}`;

    if (searchTerm) {
      query += `&s=${encodeURIComponent(searchTerm)}`;
    }

    if (fileType) {
      query += `&fileType=${encodeURIComponent(fileType)}`;
    }

    if (excludeIds.length > 0) {
      // excludeIds dizisini virgülle ayrılmış bir string'e dönüştür
      const excludeIdsParam = excludeIds.join(',');
      query += `&excludeIds=${encodeURIComponent(excludeIdsParam)}`;
    }

    // API isteğini yap
    const response = await instance.get(`/api/file/list${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};


export const renameFile = async (oldFilePath ,oldFileName, newFilePath,newFileName, fileId) => {
  try {
    const response = await instance.post('/api/file/rename', {
      oldFilePath,
      oldFileName,
      newFilePath,
      newFileName,
      fileId
    });
    return response.data; // ya da başka bir işlem, örneğin state güncelleme
  } catch (error) {
    console.error('Error renaming file:', error);
    throw error; // ya da bir hata işleme mekanizması
  }
};

export const deleteFile = async (fileId) => {
  try {
    console.log(fileId)
    // DELETE isteği ile dosyayı silmek için API'ye istek gönderin
    const response = await instance.delete(`/api/file/delete/${fileId}`);
    return response.data; // Başarılı yanıtı döndür
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error; // Hata işlemesi
  }
};

// Slug kontrolü için yeni bir fonksiyon ekleyin
export const checkSlugAvailability = async (slug) => {
  try {
    const response = await instance.get(`/api/content/checkSlug?slug=${encodeURIComponent(slug)}`);
    return response.data; // Sunucunun cevabını döndür
  } catch (error) {
    console.error('Error checking slug availability:', error);
    throw error; // Hata yönetimini çağıran fonksiyona bırak
  }
};


export const updateCategory = async (id, data) => {
  try {
    const response = await instance.put(`/api/category/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};


export const createCategory = async (data) => {
  try {
    const response = await instance.post('/api/category', data);
    return response.data;
  } catch (error) {
    console.error('Error creating category:', error);
    throw error;
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await instance.get(`/api/category/byid/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category by ID:', error);
    throw error;
  }
};

export const getCategories = async (page = 1, limit = 25, search = '') => {
  try {
    const response = await instance.get(`/api/category?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const getCategoriesWithNoParams = async () => {
  try {
    const response = await instance.get('/api/category/withnop');
    return response.data;
  } catch (error) {
    console.error('Error fetching categories with no params:', error);
    throw error;
  }
};

export const getAllCategories = async () => {
  try {
    const response = await instance.get('/api/category/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching all categories:', error);
    throw error;
  }
};

// Period CRUD operasyonları için API servisleri

export const getPeriods = async () => {
  try {
    const response = await instance.get(`/api/period`);
    return response.data;
  } catch (error) {
    console.error('Error fetching periods:', error);
    throw error;
  }
};

export const createPeriod = async (periodData) => {
  try {
    const response = await instance.post('/api/period', periodData);
    return response.data;
  } catch (error) {
    console.error('Error creating a new period:', error);
    throw error;
  }
};

export const updatePeriod = async (id, periodData) => {
  try {
    const response = await instance.put(`/api/period/${id}`, periodData);
    return response.data;
  } catch (error) {
    console.error('Error updating the period:', error);
    throw error;
  }
};

export const deletePeriod = async (id) => {
  try {
    const response = await instance.delete(`/api/period/${id}`);
    return response.data;  // Not: 204 No Content yanıtı dönerse, response.data boş olabilir.
  } catch (error) {
    console.error('Error deleting the period:', error);
    throw error;
  }
};


//Content 

// Content ekleme
export const createContent = async (contentData) => {
  try {
      const response = await instance.post('/api/content', contentData);
      return response.data;
  } catch (error) {
      console.error('Error creating content:', error);
      throw error;
  }
};

// Kategori adına göre contentleri getirme
export const getContentsByCategoryName = async (categoryName) => {
  try {
      const response = await instance.get(`/api/content/byCategoryName/${categoryName}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching contents by category name:', error);
      throw error;
  }
};

// Kategori ID'sine göre contentleri getirme
export const getContentsByCategoryId = async (categoryId) => {
  try {
      const response = await instance.get(`/api/content/byCategoryId/${categoryId}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching contents by category ID:', error);
      throw error;
  }
};

// Tüm contentleri getirme (Filtreleme ile)
export const getAllContents = async (params = {}) => {
  const query = new URLSearchParams(params).toString();
  try {
      const response = await instance.get(`/api/content/contents?${query}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching all contents:', error);
      throw error;
  }
};

