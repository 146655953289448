import React, { Suspense, lazy } from 'react';

import Footer from './components/footer';
import { LanguageProvider } from './context/LanguageContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import { SystemProvider } from './context/SystemContext';
import Playground from './components/lexical/playground';
import './App.css';

const Login = lazy(() => import('./pages/login'));
const Profile = lazy(() => import('./pages/profile'));
const SystemSetting = lazy(() => import('./pages/systemSetting'));
const StaticPages = lazy(() => import('./pages/staticPages'));
const FilesPage = lazy(()=>import('./pages/filesPages'));
const ContentPage = lazy(()=>import('./pages/content'));
const CategoryPage = lazy(()=> import('./pages/category'));
const PeriodPage = lazy(()=> import('./pages/period'));
const UnderConstructionPage = lazy(()=>import('./pages/underConstructionPage'))

function App() {
  return (
    <SystemProvider>
    <AuthProvider>
      <BrowserRouter>
        <LanguageProvider>

          <Suspense fallback={<div>Yükleniyor...</div>}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path='/' element={<ProtectedRoute />}>
                <Route path='/' element={<Profile />} />
                <Route path='/content' element={<ContentPage />} />
                <Route path='/files' element={<FilesPage />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/system-setting' element={<SystemSetting />} />
                <Route path='/newpage' element={<StaticPages />} />
                <Route path='/allpages' element={<UnderConstructionPage />} />
                <Route path='/test' element={<Playground />} />
                <Route path='/category' element={<CategoryPage />} />
                <Route path='/period' element={<PeriodPage />} />
                <Route path="*" element={<UnderConstructionPage />} />
              </Route>
            </Routes>
            <Footer />
          </Suspense>
        </LanguageProvider>

      </BrowserRouter>
    </AuthProvider>
    </SystemProvider>
  );
}

export default App;
